@import url('https://fonts.cdnfonts.com/css/avenir');

@font-face {
  font-family: Avenir-Light;
  src: url("/src/font - Copy/AvenirLTStd-Light.otf") format("opentype");
}

@font-face {
  font-family: Avenir-Book;
  src: url("/src/font - Copy/AvenirLTStd-Book.otf") format("opentype");
}
@font-face {
  font-family: Avenir-Heavy;
  src: url("/src/font - Copy/AvenirLTStd-Heavy.otf") format("opentype");
}

@font-face {
  font-family: Avenir-Black;
  src: url("/src/font - Copy/AvenirLTStd-Black.otf") format("opentype");
}
.bgg{
  background-image: url(/src/images/bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.front{
  background-image: url(/src/images/frontbg.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}
.gunabg{
  background-image: url(/src/images/gunabg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

.navbg{
  background-image: url(/src/images/menubar.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}
.gbg{
  background-image: url(/src/images/gbg.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center;
}
.g{
  background-image: url(/src/images/bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  
}

.fbg{
  background-image: url(/src/images/founderbg.png);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;

}
.carousel {
  overflow-x: scroll;
  width: 100%;
}
.carousel-item {
  flex-shrink: 0;
  width: 25%;

}



.mgbg{
  background-image: url(/src/images/box\ g.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;

  
}
.mkbg{
  background-image: url(/src/images/box\ k.png);
 
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;

}

.mrbg{
  background-image: url(/src/images/box\ r.png);

  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  
}

.mtbg{
  background-image: url(/src/images/box\ t.png);
  padding-top: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  
}

.mkitbg{
  background-image: url(/src/images/box\ kit.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;

}

.mkpranobg{
  background-image: url(/src/images/box\ prano.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;

}

.mksriyaabg{
  background-image: url(/src/images/box\ sriya.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;

}

.mksribg{
  background-image: url(/src/images/box\ s.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;

}

.mkprabg{
  background-image: url(/src/images/box\ p.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  padding-top: 23px;

}

.mcmobbg{
  background-image: url(/src/images/mancavemobilebg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.al{
  font-family: 'Avenir-Light';
}

.ah {
  
  font-family: 'Avenir-Heavy';
}

.ab {
  
  font-family: 'Avenir-Black';
}

.abk{
  font-family: 'Avenir-Book';
}



.fade {

  padding-top: 70px;
  background-color: black;
  transition: opacity 0.6s ease-in-out;

}

.fadee {

  background-color: white;
  transition: opacity 0.7s ease-in-out;
}



.fadeOut { opacity: 0; }
.fadeIn { opacity: 1; }



 [data-aos^=hide][data-aos^=hide] {
  opacity: 1;
  transition-property: opacity, transform
}

[data-aos^=hide][data-aos^=hide].aos-animate {
  opacity: 0;
  transform: translate(0)
}


[data-aos="fade-out"] {
  opacity: 1;
  transition-property: opacity;
}
[data-aos="fade-out"].aos-animate {
    opacity: 0;
  }


  
  

  .intro {

    background-color: black;

  }
  
  .intro video {
    
    scale: 60%;
    
    position: fixed;
  }
  
  .fadepara p{
    color: transparent;
  }
  
  
  
  body::-webkit-scrollbar {
    width: 8px;  
    background-color: transparent;
    z-index: 50;      /* width of the entire scrollbar */
  }
  
  #sidemenu{
    position: relative;
}
  
  body::-webkit-scrollbar-thumb {
    background-color: transparent;
    background-color: gray;    /* color of the scroll thumb */
    border-radius: 16px;       /* roundness of the scroll thumb */
    border: 5px ;/* creates padding around scroll thumb */
  }

  body::-webkit-scrollbar-track {
    
    background-color: transparent;
}



formInput{
  display: flex;
}
/* 
input{
  padding: 15px;
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid gray;
} */



.sp{
  font-size: 12px;
  padding: 2px;
  color: red;
  display: none;
}

input:invalid[focused="true"]{
  border: 1px solid red;
}

input:invalid[focused="true"] ~ span{
  display: block;
}






.scrollable-container::-webkit-scrollbar {
  height: 10px;
  width: 10px;

}

/* Track */
.scrollable-container::-webkit-scrollbar-track {
  background: black; 
  width: 50px;
}
 
/* Handle */
.scrollable-container::-webkit-scrollbar-thumb {
  background: #888; 
  width: 50px;
}

/* Handle on hover */
.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* For horizontal scrolls */
.scrollable-container::-webkit-scrollbar-thumb {
  border-radius: 10px;

}
.scrollable-container::-webkit-scrollbar-track {
  border-radius: 10px;


}

